<template>
  <div>
    <div class="order-table-wrapper" style="padding-top: 0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top: 30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 0 }"
                  @click="switchTable(0)"
                >
                  今天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 1 }"
                  @click="switchTable(1)"
                >
                  昨天
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 2 }"
                  @click="switchTable(2)"
                >
                  本月
                </div>
                <div
                  class="e-tabs-item"
                  :class="{ active: tabIndex == 3 }"
                  @click="switchTable(3)"
                >
                  上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select
                clearable
                size="small"
                v-model="departmentCode"
                placeholder="选择门店"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                :picker-options="pickerOptions"
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px;"
              >
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button
                  size="small"
                  class="e-button"
                  style="margin-left: 28px"
                  type="primary"
                  @click="getAchievement"
                >
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div v-if="isShow">
            <div>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">项目划卡劳动业绩</span>
                  <p class="item-h2">来自于: 项目业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.itemCardLabour }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">欠款劳动业绩</span>
                  <p class="item-h2">来自于: 项目业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.arrearsLabour }}
                  </p>
                </div>
              </el-card>

              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">项目现金劳动业绩</span>
                  <p class="item-h2">来自于: 项目业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.itemCashLabour }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">劳动业绩总汇</span>
                  <p class="item-h2">来自于: 项目业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.labour }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">赠送业绩总汇</span>
                  <p class="item-h2">来自于: 赠送卡金及项目业绩总汇</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.give }}
                  </p>
                </div>
              </el-card>
            </div>
            <div style="margin-top: 35px">
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">卖品划卡销售业绩</span>
                  <p class="item-h2">来自于: 卖品业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.skuCardSale }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">卖品现金销售业绩</span>
                  <p class="item-h2">来自于: 卖品业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.skuCashSale }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">套餐划卡销售业绩</span>
                  <p class="item-h2">来自于: 套餐业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.packageCardSale }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">套餐现金销售业绩</span>
                  <p class="item-h2">来自于: 套餐业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.packageCashSale }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">销售业绩总汇</span>
                  <p class="item-h2">来自于: 套餐业绩统计</p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.sale }}
                  </p>
                </div>
              </el-card>
            </div>
            <div style="margin-top: 35px">
              <el-card class="box-card" style="width: 25%">
                <div class="text-item">
                  <span class="item-h1">美团金额总汇</span>
                  <p class="item-h2"></p>
                  <p class="item-h3"><span style="color: red">¥</span>{{acRes.mtAmount}}</p>
                </div>
              </el-card>
              <el-card class="box-card" style="width: 25%">
                <div class="text-item">
                  <span class="item-h1">还款金额总汇</span>
                  <p class="item-h2"></p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.repayment }}
                  </p>
                </div>
              </el-card>
              <el-card class="box-card" style="width: 25%">
                <div class="text-item">
                  <span class="item-h1">办卡/充卡金额总汇</span>
                  <p class="item-h2"></p>
                  <p class="item-h3">
                    <span style="color: red">¥</span>{{ acRes.cardClass }}
                  </p>
                </div>
              </el-card>
            </div>
            <div style="margin-top: 35px">
              <el-card class="box-card" style="width: 45%;height: 200px;">
                <div class="text-item1">
                  <span class="item-h1">实收总现金</span>
                  <p class="item-h2">来自于: 营业额统计</p>
                  <p style="line-height: 40px;margin-left: 120px;font-weight: bold;width: 200px">
                    <span style="color: red">¥</span>{{ acRes.totalCash }}
                  </p>
                </div>
                <div class="text-item1" style="margin-left: 30%;">
                  <p class="text-item1-p">现金 {{ acRes.xjAmount }}</p>
                  <p class="text-item1-p">微信 {{ acRes.wxAmount }}</p>
                  <p class="text-item1-p">支付宝 {{ acRes.aliAmount }}</p>
                  <p class="text-item1-p">美团 {{ acRes.mtAmount }}</p>
                </div>
              </el-card>
              <el-card class="box-card" style="width: 45%;height: 200px;">
                <div class="text-item">
                  <span class="item-h1">客流分析</span>
                  <p class="item-h2">来自于: 客流统计</p>
                  <div>
                    <el-row>
                      <el-col :span="12">
                        <div class="grid-content bg-purple">
                          <ul class="passenger-left">
                            <li>男客</li>
                            <li>非指定</li>
                            <li>非会员</li>
                          </ul>
                          <ul class="passenger-right">
                            <li>{{acRes.nanNum}}</li>
                            <li>{{acRes.fzdNum}}</li>
                            <li>{{acRes.fhyNum}}</li>
                          </ul>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                          <ul class="passenger-left">
                            <li>女客</li>
                            <li>指定</li>
                            <li>会员</li>
                          </ul>
                          <ul class="passenger-right">
                            <li>{{acRes.nvNum}}</li>
                            <li>{{acRes.zdNum}}</li>
                            <li>{{acRes.hyNum}}</li>
                          </ul>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
        storeList: [],
        cxDate: [],
        departmentCode: "",
        acRes: {},
        isShow: false,
        pickerOptions: {
          onPick: ({maxDate, minDate}) => {
            this.pickDate = minDate.getTime()
            if (maxDate) {
              this.pickDate = ''
            }
          },
          disabledDate: time => {
            let choiceDateTime = new Date(this.pickDate).getTime()
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const max = maxTime
            if (this.pickDate) {
              return time.getTime() < min || time.getTime() > max;
            }
          }
        },
      };
    },
    async created() {
      this.getStoreList();
    },
    methods: {
      switchTable(index) {
        this.cxDate=[]
        this.tabIndex = index;
      },

      async getStoreList() {
        let res = await this.$get("/admin/getStoreList");
        this.storeList = res;
      },

      async getAchievement() {
        if (!this.departmentCode) {
          this.$message.error("请选择门店!")
          return;
        }
        this.isShow = false;
        let staDate = "";
        let endDate = "";
        let dateType = "";
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0];
          endDate = this.cxDate[1];
          dateType = "";
        } else {
          dateType = this.tabIndex;
        }
        let data = {
          dateType: dateType,
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
        };
        let res = await this.$get("/admin/getAchievement", data);
        if (res.code == 200) {
          this.acRes = res.acRes;
          this.isShow = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .text-item1 {
    margin-top: 30px;
    width: 35%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .text-item1-p {
      font-size: 13px;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
      span {
        margin-right: 5px;
      }
    }
  }

  .passenger-right,
  .passenger-left {
    float: left;
    font-size: 12px;
  }

  .passenger-left {
    width: 30%;
  }
</style>
