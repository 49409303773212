<template>
  <div>
    <div class="order-table-wrapper" style="padding-top:0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">今天
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==1}" @click="switchTable(1)">昨天
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==2}" @click="switchTable(2)">本月
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==3}" @click="switchTable(3)">上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select size="small"  clearable  v-model="departmentCode" placeholder="选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                size="small"
                v-model="cxDate"
                type="daterange"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" style="margin-left: 28px" type="primary"
                           @click="getAchievement">
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div v-if="isShow">
            <div>
              <span style="margin-bottom: 8px;display:block;color: #ce5e05;font-weight: bold">会员卡及卡金统计</span>
              <el-card class="box-card" v-if="cardList.length>0" v-for="item in cardList">
                <div class="text-item">
                  <span class="item-h1">{{item.cardName}}</span>
                  <p class="item-h2">数量: {{item.cardNum}} 张</p>
                  <p class="item-h3">总卡金:<span style="color: red">¥</span>{{item.cardAmount}}</p>
                  <p class="item-h2"><span>赠送金: </span>{{item.giveAmount}}</p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">会员卡总汇</span>
                  <p class="item-h2">数量: {{cardSummary.cardNum}} 张</p>
                  <p class="item-h3">总卡金: <span style="color: red">¥</span>{{cardSummary.cardAmount}}</p>
                  <p class="item-h2"><span>赠送金: </span>{{cardSummary.giveAmount}}</p>
                </div>
              </el-card>
            </div>
            <div style="margin-top: 35px">
              <span style="margin-bottom: 8px;display:block;color: #ce5e05;font-weight: bold">充卡及消卡数据统计</span>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">充卡金额总汇</span>
                  <p class="item-h3" style="margin-top: 8px">总金额: <span style="color: red">¥</span>{{reAmount}}</p>
                </div>
              </el-card>
              <el-card class="box-card">
                <div class="text-item">
                  <span class="item-h1">消卡金额总汇</span>
                  <p class="item-h3" style="margin-top: 8px">总金额: <span style="color: red">¥</span>{{elAmount}}</p>
                </div>
              </el-card>
            </div>
            <div style="margin-top: 35px">
              <span style="margin-bottom: 8px;display:block;color: #ce5e05;font-weight: bold">营业额数据统计</span>
              <el-card class="box-cards">
                <div class="text-items">
                  <span class="item-h1">实收总现金</span>
                  <p class="item-h2">来自于: 营业额业绩统计</p>
                  <p class="item-h3"><span style="color: red">¥</span>{{total}}</p>
                </div>
                <div class="text-items">
                  <span class="item-h1" style="height: 20px">数据分析</span>
                  <p class="item-h4">现金:{{xj}}</p>
                  <p class="item-h4">微信:{{wx}}</p>
                  <p class="item-h4">支付宝:{{ali}}</p>
                  <p class="item-h4">美团:{{mt}}</p>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        tabIndex: 0,
        storeList: [],
        cxDate: [],
        departmentCode: '',
        acRes: {},

        isShow: false,

        cardList: [],
        cardSummary: {},
        elAmount: '',
        reAmount: '',
        xj: '',
        wx: '',
        ali: '',
        mt: '',
        total: '',
        pickerOptions: {
          onPick: ({maxDate, minDate}) => {
            this.pickDate = minDate.getTime()
            if (maxDate) {
              this.pickDate = ''
            }
          },
          disabledDate: time => {
            let choiceDateTime = new Date(this.pickDate).getTime()
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const max = maxTime
            if (this.pickDate) {
              return time.getTime() < min || time.getTime() > max;
            }
          }
        },
      }
    },
    async created() {
      this.getStoreList()
    },
    methods: {

      switchTable(index) {
        this.cxDate=[]
        this.tabIndex = index
      },

      async getStoreList() {
        let res = await this.$get("/admin/getStoreList")
        this.storeList = res;
      },

      async getAchievement() {
        if (!this.departmentCode) {
          this.$message.error("请选择门店!")
          return;
        }
        this.isShow = false
        let staDate = '';
        let endDate = '';
        let dateType = '';
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
          dateType = ''
        } else {
          dateType = this.tabIndex
        }
        let data = {
          dateType: dateType,
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
        }
        console.log(data)
        this.isShow = true
        let res = await this.$get("/admin/getFinance", data)
        console.log(res)
        if (res.code == 200) {
          this.cardList = res.cardList;
          this.cardSummary = res.cardSummary;
          this.elAmount = res.elAmount;
          this.reAmount = res.reAmount;
          this.xj = res.xj;
          this.wx = res.wx;
          this.ali = res.ali;
          this.mt = res.mt;
          this.total = res.total;
          this.isShow = true
        }
      },

    },
  }

</script>

<style lang="scss" scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .box-cards {
    width: 35%;
    margin-right: 20px;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }

  }

  .text-items {
    width: 50%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
    .item-h4 {
      height: 20px;
      font-size: 12px;
    }

  }
</style>
